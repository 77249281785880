import { StyleSheet, Text, View, Image,TouchableOpacity } from "react-native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import React from "react";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import { isMobile, isWeb } from "../utils/Sizes";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { fontFamilies } from "../assets/fonts";
import { TouchableHighlight } from "react-native-gesture-handler";

export default function DetailPage(props) {
  const { aboutUsRef, navigation } = props;

  const scrollToComponent = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };


  
  return (
    <View style={styles.container}>
      <View style={isMobile ? styles.mainBoxForMobile : styles.mainBox}>
        <View
          style={
            isMobile ? styles.oneMobile : { width: "27%", alignItems: "center" }
          }
        >
          <Image
            source={images.logo}
            style={{
              width: isMobile ? taxiLinkFontSize(15.6) : taxiLinkFontSize(20.7),
              height: isMobile
                ? taxiLinkFontSize(8.0766)
                : taxiLinkFontSize(9.636),
              resizeMode: "contain",
            }}
          />
        </View>
        {/* <View style={isMobile ? styles.twoMobile : { width: "40%" }}>
          <Text style={styles.mainHeading}>Topptaxivägar</Text>
          <View style={styles.lowerBox}>
            <Text style={styles.text}>Nyköping C {">"} Arlanda</Text>
            <Text style={styles.text}>
              Nyköpings Centralstation {">"} Stockholm Skavsta flygplats
            </Text>
            <Text style={styles.text}>
              Nyköpings centralstation {">"} Norrköping
            </Text>
            <Text style={styles.text}>Nyköping {">"} Nävekvarn</Text>
            <Text style={styles.text}>Nyköping central {">"} Stigtomta</Text>
            <Text style={styles.text}>Nyköping central {">"} Oxelösund</Text>
            <Text style={styles.text}>
              Nyköping central {">"} Bromma flygplats
            </Text>
          </View>
        </View> */}

        <View
          style={
            isMobile
              ? styles.threeMobile
              : {
                  width: "60%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingRight: taxiLinkFontSize(7),
                }
          }
        >
          <View style={{ width: isMobile ? "40%" : '50%', alignItems: isWeb && "center", }}>
            <Text style={styles.mainHeading}>Handla om</Text>
            <TouchableOpacity onPress={()=> scrollToComponent(aboutUsRef)} style={styles.lowerBox}>
              <Text style={styles.text}>Om oss</Text>
              {/* <Text style={styles.text}>Hjälpcenter</Text> */}
            </TouchableOpacity>
          </View>

          {isMobile && <View style={{ width: "10%" }}></View>}
          <View
            style={{
              width: isMobile ? "40%" : '50%',
            }}
          >
            <Text style={styles.mainHeading}>Säkerhet</Text>
            <View style={styles.lowerBox}>
              <Text
                style={styles.text}
                onPress={() => navigation.navigate("TermAndConditions")}
              >
                Användarvillkor
              </Text>
              <Text style={styles.text}>Integritet</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.BottomView}>
        <Text style={styles.copyright}>©2023, Taxi Link</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb ? taxiLinkFontSize(36.5) : taxiLinkFontSize(51.1),
    paddingTop: isWeb && taxiLinkFontSize(5.6),
    backgroundColor: COLORS.blackBg,
  },
  mainBox: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    flexDirection: "row",
  },

  mainBoxForMobile: {
    width: "90%",
    height: "90%",
    alignSelf: "center",
  },
  oneMobile: {
    width: "100%",
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: taxiLinkFontSize(3),
  },
  twoMobile: {
    width: "100%",
    // flex: 3,
    paddingLeft: "3%",
    marginTop: taxiLinkFontSize(6),
  },
  threeMobile: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: "3%",
    justifyContent: "space-between",
    marginTop: taxiLinkFontSize(8),
  },

  flexStyle: {
    flex: 1,
    // alignItems: 'center',
    padding: 5,
  },
  BottomView: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "10%",
    position: "absolute",
    bottom: 0,
  },
  mainHeading: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isMobile ? taxiLinkFontSize(1.8) : taxiLinkFontSize(2.2),
    fontWeight: "700",
    marginBottom: taxiLinkFontSize(1.5),
  },
  lowerBox: {
    marginTop: isWeb ? "2%" : taxiLinkFontSize(0),
  },
  text: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(1.8) : taxiLinkFontSize(1.6),
    // marginTop: "10%",
    lineHeight: 26,
    marginTop: isWeb ? taxiLinkFontSize(1.2) : taxiLinkFontSize(0.7),
  },
  two: {
    flex: 5,
    justifyContent: "space-evenly",
  },
  seconText: {
    fontWeight: "700",
    color: COLORS.primary,
    fontSize: RFPercentage(0.9),
  },
  one: {
    flexDirection: "row",
    width: "80%",
    height: "10%",
    marginTop: "15%",
  },
  innerone: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emailImg: {
    width: "60%",
    height: "60%",
    resizeMode: "contain",
  },
  imgLink: {
    flexDirection: "row",
    width: "50%",
    marginTop: "15%",
  },
  linkImg: {
    width: 25,
    height: 25,
    marginLeft: "5%",
    resizeMode: "contain",
  },
  copyright: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isMobile ? taxiLinkFontSize(1.8) : taxiLinkFontSize(1.9),
    fontWeight: "400",
    marginBottom: taxiLinkFontSize(1.5),
  },
});
