import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  useWindowDimensions,
  Dimensions,
  ScrollView,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";
import { COLORS, SIZES } from "../utils";
import PriceCard from "./PriceCard";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { fontFamilies } from "../assets/fonts";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { isWeb } from "../utils/Sizes";
import { images } from "../assets";
import { useIsFocused } from "@react-navigation/native";

export default function Pricing({ bookNowRef }) {
  // const { width, he } = useWindowDimensions();
  const deviceWidth = Dimensions.get("window").width;

  console.log(deviceWidth + " this is   ");
  const array = [
    {
      id: 1,
      headingText: "Nyköping C till Arlanda",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 2350",
    },
    {
      id: 2,
      headingText: "Nyköping Central Station till Stockholm Skavsta flygplats",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 260",
    },
    {
      id: 3,
      headingText: "Nyköping central station till Norrköping",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 1200",
    },
    {
      id: 4,
      headingText: "Nyköping till Nävekvarn",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 599",
    },
    {
      id: 5,
      headingText: "Nyköping central till Stigtomta",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 599",
    },
    {
      id: 6,
      headingText: "Nyköping central till Oxelösund",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 450",
    },
    {
      id: 7,
      headingText: "Nyköping central till Bromma flygplats",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "SEK 1950",
    },
    {
      id: 8,
      headingText: "Nyköping Central till Eskilstuna Central",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "1650kr",
    },
    {
      id: 9,
      headingText: "Nyköping Central till Flen",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "1200kr",
    },
    {
      id: 10,
      headingText: "Nyköping Central till Katrineholm",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "1200kr",
    },
    {
      id: 11,
      headingText: "Nyköping Central till Södertälje syd",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "1200kr",
    },
    {
      id: 12,
      headingText: "Nyköping till Gnesta",
      description: "För kunders bekvämlighet och komfort erbjuder vi följande paket.",
      price: "925kr",
    },
  ];

  const [cards, setCards] = useState([]);
  const isFocused = useIsFocused();
  useEffect(() => {
    setCards(isWeb ? array.slice(0, 4) : array.slice(0, 3));
  }, [isFocused]);

  const seeMoreBtn = () => {
    return (
      <TouchableOpacity
        style={[styles.seeMoreLessBtn, {marginBottom: taxiLinkFontSize(5)}]}
        onPress={() => setCards(array)}
      >
        <Text style={styles.seeMoreLessText}>Se mere</Text>
        <Image source={images.downArrow} style={styles.imgW23H28} />
      </TouchableOpacity>
    );
  };

  const seeLessBtn = () => {
    return (
      <TouchableOpacity
        style={[styles.seeMoreLessBtn, {marginBottom: taxiLinkFontSize(6)}]}
        onPress={() => setCards(isWeb ? array.slice(0, 4) : array.slice(0, 3))}
      >
        <Text style={styles.seeMoreLessText}>Se Mindre</Text>
        <Image source={images.upArrow} style={styles.imgW23H28} />
      </TouchableOpacity>
    );
  };

  console.log("cards: ", cards);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headingText}>Prissättning</Text>
      </View>

      <View style={styles.restview}>
        <View style={{ width: isWeb ? "90%" : "75%", alignSelf: "center" }}>
          <Text style={styles.txt}>
          Bomavgifter läggs till vid hämtning och i vissa fall avlämning på flygplatser, vilka specificeras separat på kvittot. I vissa fall kan det visade priset för resan ändras på grund av extra väntetider, extra hämtning/avlämningsplatser och körning till avlägsna områden. Om den överenskomna tiden inte följs av taxikunden läggs tillämplig väntetidstaxa till det fasta priset. Vid flygplatser ingår väntetid, exempelvis 15 minuter vid Skavsta, 30 minuter vid Arlanda, Bromma och Kungsängen Norrköping. Om du önskar barnstolar kostar det ytterligare 100 SEK inklusive moms; ange åldern på barnet/barnen! En bälteskudde finns i varje taxi; om du behöver fler, nämna det i beställningen.
          </Text>
        </View>
        <View style={styles.cardBox}>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            showsHorizontalScrollIndicator={false}
          >
            {cards.map((item) => {
              return (
                <>
                  <PriceCard item={item} bookNowRef={bookNowRef} />
                  <View
                    style={{
                      height: isWeb
                        ? taxiLinkFontSize(2)
                        : taxiLinkFontSize(3.9),
                    }}
                  ></View>
                </>
              );
            })}
            {array.length === cards.length ? seeLessBtn() : seeMoreBtn()}
          </ScrollView>
        </View>
        <View style={styles.lowerBox}>
          <Image
            source={images.bar}
            style={{ width: "100%", height: "100%", resizeMode: "stretch" }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb ? taxiLinkFontSize(174.6) : taxiLinkFontSize(156.7),
    backgroundColor: COLORS.blackBg,
  },
  header: {
    width: "100%",
    height: "10%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: isWeb && taxiLinkFontSize(5),
  },
  restview: {
    width: "100%",
    height: "85%",
    marginTop: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(1),
  },
  headingText: {
    color: COLORS.primary,
    fontFamily: fontFamilies.Popins,
    fontSize: taxiLinkFontSize(5.1),
    fontWeight: "800",
  },
  txt: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(1.4),
    fontWeight: "400",
    lineHeight: 30,
    textAlign: "center",
  },
  cardBox: {
    flex: 1,
    width: "80%",
    alignSelf: "center",
    marginTop: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(5),
    // backgroundColor: 'green',
    marginBottom: "4%",
  },
  lowerBox: {
    width: "100%",
    height: isWeb ? taxiLinkFontSize(11.1) : taxiLinkFontSize(7.6),
    marginTop: isWeb && taxiLinkFontSize(1),
  },
  imgW23H28: {
    width: taxiLinkFontSize(2.3),
    height: taxiLinkFontSize(2.86),
    resizeMode: "contain",
    marginTop: taxiLinkFontSize(0.5),
  },
  seeMoreLessBtn: {
    alignItems: "center",
  },
  seeMoreLessText: {
    color: COLORS.primary,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "400",
    fontSize: taxiLinkFontSize(2.6),
    lineHeight: taxiLinkFontSize(3),
  },
});
