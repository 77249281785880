import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { SIZES, COLORS } from "../utils";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { isMobile, isWeb } from "../utils/Sizes";
import { fontFamilies } from "../assets/fonts";
const PriceCard = (props) => {
  const deviceWidth = Dimensions.get("window").width;
  const { item, bookNowRef } = props;

  return (
    <View style={styles.container}>
      <View style={styles.leftView}>
        <View style={styles.headingTextContainer}>
          <Text style={styles.headingTextStyle}>{item?.headingText}</Text>
        </View>
        <Text style={styles.descriptionStyle}>{item?.description}</Text>
      </View>
      <View style={styles.rightView}>
        <View style={styles.textContainer}>
          <Text style={styles.priceTextStyle}>{item?.price}</Text>
          <TouchableOpacity style={styles.btnStyle} onPress={()=> bookNowRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })}>
            <Text style={styles.btnText}>
            Boka nu
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb ? taxiLinkFontSize(21.6) : taxiLinkFontSize(16),
    backgroundColor: COLORS.white,
    flexDirection: isMobile ? "column" : "row",
    borderRadius: 7,
    marginTop: "2%",
    paddingLeft: isWeb && taxiLinkFontSize(1.5),
    paddingRight: isWeb && taxiLinkFontSize(3.6),
    alignItems: isMobile? 'center' : null,
    borderRadius: taxiLinkFontSize(1.2),
    justifyContent: isWeb && "space-between"
  },
  leftView: {
    width: isMobile ? "100%" : "60%",
    height: isMobile ? "50%" : "100%",
    padding: "2%",
    justifyContent: isWeb && "space-evenly",
    alignItems: isMobile ? "center" : null,
  },
  headingTextStyle: {
    color: COLORS.gray24,
    fontFamily: fontFamilies.Popins,
    fontSize: isWeb ? taxiLinkFontSize(3.4) : taxiLinkFontSize(1.8),
    fontWeight: "500",
    textAlign: isMobile && "center",
    lineHeight: isMobile? taxiLinkFontSize(2) : taxiLinkFontSize(4.4),
  },
  descriptionStyle: {
    color: COLORS.gray24,
    textAlign:isMobile && "center",
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(1.8) : taxiLinkFontSize(1),
    fontWeight: "400",
    marginTop: isMobile && taxiLinkFontSize(0.8),
  },
  rightView: {
    width: isMobile ? "100%" : "25%",
    height: isMobile ? "50%" : "100%",
    justifyContent: "center",
    alignItems: isWeb? "flex-end" : 'center',
  },
  textContainer: {
    width: isMobile ? "70%" : "100%",
    height: "100%",
    justifyContent: isMobile ? 'space-between' : "center",
    alignItems: "center",
    flexDirection: isMobile ? "row" : "column",
  },
  priceTextStyle: {
    color: COLORS.kettleBlack,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(4.8) : taxiLinkFontSize(2.6),
    fontWeight: "700",
    letterSpacing: isWeb && -1.78
  },
  btnStyle: {
    width: isMobile ? "40%" : "100%",
    height: isMobile ? taxiLinkFontSize(3.4) : taxiLinkFontSize(5.6),
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: taxiLinkFontSize(0.02),
    marginTop: isWeb && taxiLinkFontSize(0.8)
  },
  btnText: {
    color: COLORS.kettleBlack,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(2.4) : taxiLinkFontSize(1.6),
    fontWeight: "500",
  },
  headingTextContainer: {
    width: isMobile ? "80%" : null,
    marginTop: isMobile ? "3%" : null
  },
});

export default PriceCard;
