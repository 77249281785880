import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from '../screens/HomeScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { COLORS } from '../utils';
import TermAndConditions from '../screens/TermAndConditions';

export default function Routs() {
    const Stack = createNativeStackNavigator();
    return (
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
                <Stack.Screen name="TermAndConditions" component={TermAndConditions} options={{ headerShown: false }} />
            </Stack.Navigator>
        </NavigationContainer>
    )
}

export const DrawerNavigator = () => {
    const Drawer = createDrawerNavigator()
    return (
        <Drawer.Navigator
            useLegacyImplementation={false}
            // drawerContent={props => <DrawerContent {...props} />}
            screenOptions={{
                headerShown: false,
                drawerContentStyle: {
                    backgroundColor: COLORS.blackBg,
                },
                drawerActiveTintColor: COLORS.white,
                
            }}
            >
            <Drawer.Screen
                name='Home'
                component={HomeScreen}
            />
        </Drawer.Navigator>
    )
}

const styles = StyleSheet.create({})