import { ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useRef } from "react";
import FirstComponent from "../components/FirstComponent";
import BookingSection from "../components/BookingSection";
import TexiImage from "../components/TexiImage";
import AboutTexiLink from "../components/AboutTexiLink";
import ContactUS from "../components/ContactUS";
import DetailPage from "../components/DetailPage";
import Pricing from "../components/Pricing";

export default function HomeScreen({ navigation, route }) {
  const firstComponentRef = useRef(null);
  const bookNowRef = useRef(null);
  const contactUsRef = useRef(null);
  const aboutUsRef = useRef(null);

  return (
    <ScrollView style={styles.container}>
      <View ref={firstComponentRef}>
        <FirstComponent
          firstComponentRef={firstComponentRef}
          bookNowRef={bookNowRef}
          contactUsRef={contactUsRef}
          aboutUsRef={aboutUsRef}
          navigation={navigation}
          route={route}
        />
      </View>
      <View ref={bookNowRef}>
        <BookingSection navigation={navigation}/>{" "}
      </View>
      <TexiImage />
      <View ref={aboutUsRef}>
        <AboutTexiLink />
      </View>
      <Pricing bookNowRef={bookNowRef}/>
      <View ref={contactUsRef}>
        <ContactUS />
      </View>
      <DetailPage navigation={navigation} aboutUsRef={aboutUsRef} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  },
});
