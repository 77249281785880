import React, { useState } from 'react'
import { StyleSheet, Text, View, Platform, Image, TouchableOpacity, ScrollView, } from 'react-native'
import HeaderForTermAndCondition from '../components/HeaderForTermAndCondition'
import { COLORS } from '../utils'
import { Picker } from '@react-native-picker/picker';
import { fontFamilies } from "../assets/fonts";
import { isWeb, isMobile } from '../utils/Sizes'
import { taxiLinkFontSize } from '../utils/responsiveDimensions'
import { images } from '../assets';
import MobileHeader from "../components/MobileHeader";
export default function TermAndConditions({navigation, route}) {

    const array = [
        {
            id: 1,
            text: 'General Terms for Taxi Booking',
            description:`By accessing or using the services provided by Taxi Link("we," "us," or "our"), you agree to comply with and be bound by the following terms and conditions of use. If you do not agree to these terms, please do not use our services.

            The following Terms sets out the conditions for using our reservation service for taxi transportation as well as associated services, e.g. an overview of previous trips, pre-registered payment details or other services as from time to time made available (the "Services") through our website 
            
            As these Terms govern your access or usage of Services made available by Taxilink and are part of your contract with Taxi Link we kindly ask you to read them carefully and thoroughly. Taxi Link may amend the Terms from time to time. Amendments will be effective upon Taxi Link posting the updated Terms. Your continued access and usage of the Services after such posting constitutes your approval of the amendments.
            
            Certain features of our services may require you to create a user account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            
            SCOPE OF SERVICES  
            Taxi Link provides an online platform that enables users to book taxi services. The service includes user registration, search and booking functionality for taxi services.
            The service is available to users 24/7, allowing for taxi bookings at any time. The duration of service usage is contingent upon the user's needs and preferences.
            By making a reservation through us you enter into a legally binding agreement regarding transportations with the Suppliers. We then act as an intermediary between you and the Supplier, sending your reservation details to the Supplier and sending you the booking confirmation and other relevant information related to the booking. We also provide an overview of previous trips. By accepting these Terms, you hereby confirm that we do not provide any transportation services and that all taxi transportation services are provided by independent suppliers.
            
            The information disclosed in our platforms are based on data provided by the Suppliers. The Suppliers are responsible for providing updated data on rates, availability etc.
            
            
            BOOKING REQUESTS 
            Your booking request will be handled by our servers. We will send your booking information to the Suppliers and check for availability. An automatic booking confirmation will be sent to you when we have received confirmation from a Supplier that your request has been accepted. Please read the booking confirmation carefully and check whether it is correct and contact us immediately should any information in the booking confirmation not correspond with your request.
            
            The time for driving to pick up, start of the journey, and arrival at the final destination depends on various factors such as. when the order is made, the availability of car, the traffic and weather conditions and the demand for other journeys at the time the journey is to take place. It is the traveler's responsibility to plan his trip well in advance, but taking into account the above. However, the points stated are assumptions and recommendations and not binding on Taxi Link.
            
            
            ACCOUNT AND PAYMENT TERMS 
            
            In order to use most aspects of the Services, you must register for and maintain an account. You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18) in order to do booking. You are also requested to submit more detailed personal information.
            
            After a completed trip the total fare will be charged.
            
            INTELLECTUAL PROPERTY 
            
            The content and information in our platforms (including, without limitation, price and availability of Services), as well as the infrastructure used to provide such content and information, is proprietary to us or our suppliers and providers. Accordingly, as a condition of using the platform, you agree not to use the platform or its contents or information for any commercial purpose (direct or indirect). While you may make limited copies of your travel itinerary (and related documents) for travel or services purchased through the platform, you agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, sell or resell any information obtained from the platform.
            
            In addition, whether or not you have a commercial purpose, you agree not to:
            
            (i) access, monitor or copy any content or information on the platform using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;
            
            (ii) violate the restrictions in any robot exclusion headers on the platform or bypass or circumvent other measures employed to prevent or limit access to the platform;
            
            (iii) take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure, or
            
            (iv) deeplink to any portion of the platform (including, without limitation, the purchase path for any services) for any purpose without our express written permission.
            
            LIMITATION OF LIABILITY
            
            Subject to the limitations set out in these Terms and to the extent permitted by law, we shall only be liable for direct damages actually suffered, paid or incurred by you due to an attributable shortcoming of our obligations in respect to our Services, up to an aggregate amount of the aggregate cost of your reservation (whether for one event or series of connected events).
            
            To the fullest extent permitted by law we shall have no obligation or liability (whether arising in contract, tort, warranty or otherwise) for any incidental, indirect or consequential damages or liabilities (including but not limited to loss of data, profit, revenue or similar) arising with respect to the Services.
            
            APPLICABLE LAW, DISPUTES ETC.
            This Agreement, and any disputes arising out of or in connection with this Agreement, shall be governed by the laws of Sweden.
            
            If any provision of these Terms is or becomes invalid, unenforceable or non-binding, you shall remain bound by all other provisions hereof. In such event, any invalid provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and you will at least agree to accept a similar effect as the invalid, unenforceable or non-binding provision, given the contents and purpose of these Terms.
            `
        },
        {
            id: 2,
            text: 'General Terms for business travelers',
            description:`OBJECT

            Taxi Link offers a business travel solution (hereinafter "the Service") that enables users of the website, to book and schedule taxi transports via a technical platform. Through the Service, the Customer receives an overview of the users' completed taxi transports and can set parameters, such as cost-center and various options for all bookings. Travel and bookings are carried out by the individual users in accordance with the Service's terms of use set out in this agreement. These general customer terms, When purchasing taxi rides in one of Taxi Link affiliated and connected taxis, apply
            
            
            
            IMPLEMENTATION OF THE SERVICE.
            
            The Customer and Taxi Link  shall work together to ensure that the Customer's employees can use the Service. This means e.g. that the Customer provides information about the intended users, Taxi Link sets up user accounts, holds reviews of the Service for the Customer's employees (according to a special agreement), provides instruction manuals, etc. All activities must be preceded by reconciliations with the Customer
            `

        },
        {
            id: 3,
            text: 'Privacy Policy',
            description:`PRIVACY POLICY 

            This privacy policy ("Policy") have been issued by Taxi Link, a Swedish registered address Box Skolörtsgången 63, 611 46 Nyköping. In these the Terms "we", "our" or "us" refer to Taxi Link and "you", "yours" and "your" refer to the person who enters into the agreement with Taxi Link.
            
            Taxi Link will act as a data controller, unless otherwise is agreed in a separate agreement. In case of questions regarding Taxi Link processing of data, please contact Taxi Link at info@Taxilink.se  or at such contact details that are posted from time to time on our platforms.
            
            PERSONAL DATA – PRIVACY POLICY
            
             Your privacy is important to us. For that reason we are committed to protect and safeguard your information and to minimize the amount of personal data we collect. In this document we describe how we use and process your personal data. This Policy provides users with information about the nature, scope and purpose of our collection and usage of personal data. Our intention is to provide the information in an understandable and transparent way.
            
            We might amend this Policy from time to time. If you care about your privacy read the Policy regularly and you will know exactly what we do and why. If we make changes to the Policy which will have an impact on you (for example, if we intend to process your personal data for other purposes than communicated in the past in this Policy), we will notify you of these changes before the new activities begin.
            
            If you disagree with this Policy, you should discontinue using our services. If you agree with our Policy, then you are all set to book your next taxi ride through us.
            
            1.1 What information is collected? If are familiar with our services since before, you’ll know that we offer taxi-related services through our own websites and mobile apps. Unless otherwise is agreed in a separate written agreement with us, we only offer a platform that makes it possible to reserve taxi transportation with taxi compay ("Supplier"). The Supplier can advertise their transportation services in the platform, and the users can use the platforms to make reservations with the Supplier. By making a reservation through us you enter into a legally binding agreement regarding transportations with the Supplier. We then act as an intermediary between you and the Supplier, sending your reservation details to the Supplier and sending you the booking confirmation and other relevant information related to the booking. We also provide an overview of previous trips.
            
            In order for our services to function we need to collect some personal data from our users:
            (a) You are therefore required to provide name and phone number at registration.
            (b) You are also requested to submit additional personal data, e.g. e-mail address, age and payment details.
            (c) We may also register additional information depending on your usage of the services, e.g. frequently used addresses, transactional information, troubleshooting, information submitted when in contact with service staff, submitted ratings etc.
            (d) We will also collect data regarding the date and time you accessed our platforms, the hardware and software (including choice of browser, language settings etc.) used when visiting our platforms, call and SMS data for contacts between driver and user, user pattern (clicks, taps, visited pages, cookies or similar), IP-addresses.
            (e) If you are booking through our mobile application we will also collect information regarding your gps-position, device specific settings and other characteristics of your phone, information regarding app crashes and other system activity.
            (f) We may also collect information during your trip in order to facilitate payment through the app. If your employer has entered into an agreement on taxi transportation with us, we may receive information about you from your employer.
            (g) When using our services, the driver may provide us with an user rating after completed trip.
            Should you want to minimize the amount of personal data that is collected we refer you to bookings through our call centres. When booking through our call centres we will only store information regarding the phone number used to make the booking. Please note however that the phone call may be recorded.
            
            You always have the right to review the personal information we keep about you. You can request an overview of your personal data by contacting us on info@Taxilink.se or at such contact details that are posted from time to time on our platforms. If you find any personal data registered by us to be incomplete, inaccurate or misleading we are obligated to correct such erroneous information.
            
            1.2 What is the purpose of us collecting and using personal data? We use the information for various purposes; the overall purpose is to provide you with a world-class booking service. Your personal data may be used for the following reasons:
            (i) Administrating booking requests and offering transportation services . This includes the possibility to swiftly book transportation, for users to follow a booked car as it is dispatched to pick-up as well as for drivers to identify the location of the users and to contact the user, to facilitate a swift and smooth payment of the trip as well as to have a complete overview of previous trips all services provided through one application. To provide you with our services, we will need to collect and use your personal data.
            (ii) Customer service. In order to ensure that you get the customer service you deserve we may share your personal data with our customer service staff, e.g. to assist you with amending a booking request, getting in touch with the driver etc.
            (iii) Account administration. By collecting and storing your personal data we can create personal accounts for our customers. Through the personal account you are able to file booking requests, get an overview of previous trips, register payment details in order to ensure a smooth ride and much more.
            (iv) Marketing activities. Based on the information you share with us we can create personalised offers, offer discounts or other promotional activities. These activities may include:
            
            a. Regular news letters to your e-mail. In case you do not want to receive these e-mails you can always click the "unsubscribe" link included in each mail.
            b. Individualised offers which entitles you to discounts etc. In case you do not want to receive these e-mails you can always click the "unsubscribe" link included in each offer.
            c. Market research. We sometimes ask you to participate in market research in order to improve our services.
            
            
            (viii) Legal purposes. In order to handle and resolve legal disputes or for regulatory compliance (including legal requirements relating to bookkeeping etc.) we may have to use personal data.
            
            1.3 What is the legal basis for us to process the data as described above? In view of purpose (i) and (ii), we rely on the legal basis that the processing of the personal data is necessary for the performance of a contract, specifically to provide and administer the taxi ride you have booked. If the required personal data is not provided, we are unable to provide you with the taxi ride and we cannot provide you with customer service.
            In view of purposes (iii) to (vii), we rely on our legitimate commercial business interest to provide our services, to prevent fraud and to improve our services. When using personal data to serve ours or a third party's legitimate interest, we will always balance your rights and interests in the protection of your personal data against our legitimate rights and interests or those of the third party.
            
            For purpose (viii), we rely on compliance with legal obligations (such as lawful law enforcement requests).
            
            1.4 Will the personal data be shared with any third parties? In order fulfil the purpose of the services we will share your personal data with the financial institutions necessary in order to process payment. We may also share personal data with third party analytics providers in order to improve our services. By using the services you also authorise us to share your personal data with the Suppliers. The Driver consist of dispatch centrals specialised in taxi transportation. Each dispatch central either have own licensed taxi cars or have entered into agreements with independent contractors with license to carry out transportation of passengers. The driver may therefore, from time to time, need to show your personal data to independent contractors. With the exemption of the above, personal data will only be forwarded to a third party if you have given your explicit consent, when it is necessary in order for us to fulfil the agreement with you, or when we are obliged to do so according to mandatory law or other regulation.
            `

        }
    ]


    const handleLanguageChange = (itemValue, itemIndex) => {
        const selectedItem = array[itemIndex];
        setSelectedLanguage(itemValue);
        setDescription(selectedItem.description);
      };

    const [selectedLanguage, setSelectedLanguage] = useState(array[0].text);
    const [description, setDescription] = useState(array[0].description);
    return (
        <View style={styles.container}>
            <View style={{ width: '100%', height: isWeb ? '38%' : '30%', backgroundColor: COLORS.offBlack }}>
                {/* <HeaderForTermAndCondition /> */}
                <MobileHeader navigation={navigation} route={route}/>

                <TouchableOpacity style={isWeb ? styles.webLogo : styles.mobLogo} activeOpacity={1} onPress={()=> navigation.goBack()}>
                    <Image
                        source={images.logo}
                        style={{
                            width: isWeb ? taxiLinkFontSize(18) : taxiLinkFontSize(16),
                            height: isWeb ? taxiLinkFontSize(9) : taxiLinkFontSize(7),
                            resizeMode: 'contain'
                        }}
                    />
                </TouchableOpacity>

                <View style={styles.logoTextContainer}>
                    <Text style={{ color: 'white', fontFamily: fontFamilies.Popins, fontSize: isWeb ? taxiLinkFontSize(5.5) : taxiLinkFontSize(3.2), fontWeight: '800' }}>Terms of Service</Text>
                </View>

            </View>

            <View style={styles.textContainer}>
                <View style={isWeb ? styles.leftContent : styles.leftContentMobile}>
                    {
                        isWeb ?
                            <>
                                {
                                    array?.map((item) => {
                                        return (
                                            <TouchableOpacity onPress={() => {setDescription(item.description),setSelectedLanguage(item.text) }} style={styles.leftInnerView}>
                                                <Text style={styles.leftText}>{item.text}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </>

                            :
                            <Picker selectedValue={selectedLanguage}
                                style={styles.dropdownStyle}
                                
                                onValueChange={handleLanguageChange}>
                                <>
                                    {
                                        array?.map((item, index) => (
                                            <Picker.Item key={index} numberOfLines={1} label={item.text} value={item.text} />
                                        ))
                                    }
                                </>

                            </Picker>

                        // <Picker
                        //     selectedValue={selectedLanguage}
                        //     onValueChange={(itemValue, itemIndex) =>
                        //         setSelectedLanguage(itemValue)
                        //     }>
                        //     <Picker.Item label="Java" value="java" />
                        //     <Picker.Item label="JavaScript" value="js" />
                        // </Picker>

                    }




                </View>
                <View style={styles.rightContent}>
                    <View style={{ width: '100%', height:isWeb? '20%':'15%', paddingLeft: '3%', justifyContent: 'center', marginTop: isWeb ? '2%' : null, alignItems: isMobile ? 'center' : null, }}>
                        <Text style={{ color: COLORS.secondary, fontFamily: fontFamilies.Inter, fontWeight: '400', fontSize: isWeb ? taxiLinkFontSize(3) : taxiLinkFontSize(2) }}>{selectedLanguage}</Text>
                    </View>
                    <View style={{ width: '100%', height: '70%',paddingHorizontal:isWeb?'4%':'4%',paddingBottom:10 }}>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <Text style={{textAlign:'justify',fontFamily:fontFamilies.Inter,fontSize:isWeb?taxiLinkFontSize(1.4):taxiLinkFontSize(1.1),color:COLORS.secondary}}>{description}</Text>
                        </ScrollView>
                    </View>
                </View>
            </View>

        </View >
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        // backgroundColor: 'red',
    },
    webLogo: {
        width: '100%',
        // height: '50%',
        // justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',

    },
    mobLogo: {
        width: 128,
        // height: 57,
        // height: '50%',

        // marginTop: 20,
        // backgroundColor:'blue',

        alignSelf: 'center',
    },
    logoTextContainer: {
        width: '100%',
        height: '30%',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red',
        position: "absolute",
        bottom: 0
    },
    textContainer: {
        width: '100%',
        height: isWeb ? '62%' : '70%',
        backgroundColor: COLORS.navyBlue,
        flexDirection: isWeb ? 'row' : 'column'
    },
    leftContent: {
        width: '30%',
        height: '90%',
        alignItems: 'center',
        paddingTop: '3%',
        // backgroundColor: 'red'
    },
    rightContent: {
        width: isWeb ? '70%' : '100%',
        height: '100%',
        // backgroundColor: 'green'
    },
    leftInnerView: {
        width: '90%',
        height: 65,
        borderBottomColor: COLORS.secondary,
        marginTop: '3%',
        paddingHorizontal: 5,
        justifyContent: 'center',
        borderBottomWidth: .5,

    },
    leftText: {
        fontFamily: fontFamilies.Inter,
        fontSize: taxiLinkFontSize(1.8),
        fontWeight: '400',
        color: COLORS.secondary
    },
    leftContentMobile: {
        width: '100%',
        height: 56,
        padding: 10,
        backgroundColor: COLORS.secondary
    },
    dropdownStyle: {
        width: '100%', height: '100%', borderWidth: 0,
    }
})