import { StyleSheet, Text, View,Image } from 'react-native'
import React from 'react'
import {images} from '../assets'
import { SIZES } from '../utils'
import { isWeb } from '../utils/Sizes'
import { taxiLinkFontSize } from '../utils/responsiveDimensions'

export default function TexiImage() {
  return (
    <View style={styles.container}>
      <Image source={images.texiimg} style={{width:'100%',height:'100%',resizeMode:'stretch'}} />
    </View>
  )
}
const styles = StyleSheet.create({
    container:{
        width:'100%',
        height: isWeb? taxiLinkFontSize(59.7) : taxiLinkFontSize(28.2)
    }
})