import {
  StyleSheet,
  Text,
  View,
  Image,
  StatusBar,
  Platform,
  TouchableOpacity,
  Dimensions,
  Linking,
} from "react-native";
import React from "react";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import { isMobile, isWeb } from "../utils/Sizes";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { InterRegular, fontFamilies } from "../assets/fonts";

const deviceWidth = Dimensions.get("window").width;
export default function MobileHeader(props) {
  const { navigation, route } = props;

  return (
    <View style={styles.container}>
      <View style={styles.one}>
        {route?.name === "TermAndConditions" && (
          <TouchableOpacity
            style={styles.imgBox}
            onPress={() => navigation.goBack()}
          >
            <Image source={images.goBack} style={styles.goBack} />
          </TouchableOpacity>
        )}
        <View style={styles.imgBox}>
          <Image
            source={images.Vector}
            style={isMobile ? styles.mobLocationImg : styles.webLocationImg}
          />
        </View>
        <Text
          style={isMobile ? styles.mobLocationText : styles.webLocationText}
        >
          Skolörtsgången 63, 611 46 Nyköping
        </Text>
      </View>
      <View style={styles.one1}>
        {isWeb ? (
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("https://dashboard.taxilink.se/");
            }}
            style={styles.driverLogin}
          >
            <Text style={styles.deriverLogin}>Inloggning för drivrutinen</Text>
          </TouchableOpacity>
        ) : null}

        <View style={styles.imgBox}>
          <Image
            source={images.clock}
            style={isMobile ? styles.mobTimeImg : styles.webTimeImg}
          />
        </View>
        <Text style={isWeb ? styles.webAvaiable : styles.mobAvailable}>
          24/24 - 7/7
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb ? 70 : 50,
    // ...Platform.select({android:{backgroundColor:'black',}}),
    //backgroundColor:'black',
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: isWeb ? 30 : 15,
    justifyContent: "space-between",
    backgroundColor: isWeb ? COLORS.transparent : COLORS.blackBg,
  },
  one: {
    flexDirection: "row",
    // ...Platform.select({ web: { width: "15%" }, android: { width: "40%" } }),

    height: 40,
    alignItems: "center",
    justifyContent: "space-between",
  },

  one1: {
    flexDirection: "row",
    // ...Platform.select({ web: { width: "25%" }, android: { width: "40%" } }),

    // height: 40,
    alignItems: "center",
    justifyContent: "space-between",
  },

  imgBox: {
    alignItems: "center",
    justifyContent: "center",
  },
  driverLogin: {
    // width: taxiLinkFontSize(13.8),
    height: taxiLinkFontSize(3.4),
    backgroundColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: COLORS.pureBlack,
    paddingHorizontal: taxiLinkFontSize(0.7),
  },
  webLocationText: {
    color: COLORS.white,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.4),
    fontWeight: "500",
    marginLeft: 15,
  },
  mobLocationText: {
    color: COLORS.white,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.2),
    fontWeight: "400",
    marginLeft: deviceWidth < 361 ? taxiLinkFontSize(0.5) : taxiLinkFontSize(1),
  },
  mobLocationImg: {
    width: taxiLinkFontSize(1.2),
    height: taxiLinkFontSize(1.8),
    resizeMode: "contain",
  },
  webLocationImg: {
    width: taxiLinkFontSize(1.7),
    height: taxiLinkFontSize(2.8),
    resizeMode: "contain",
  },
  mobTimeImg: {
    width: taxiLinkFontSize(1.7),
    height: taxiLinkFontSize(1.7),
    resizeMode: "contain",
    marginLeft: taxiLinkFontSize(1.5),
    marginRight: deviceWidth < 361 ? 0 : taxiLinkFontSize(1.5),
  },
  webTimeImg: {
    width: taxiLinkFontSize(2.5),
    height: taxiLinkFontSize(2.5),
    resizeMode: "contain",
    marginLeft: taxiLinkFontSize(2.5),
    marginRight: taxiLinkFontSize(1),
  },
  deriverLogin: {
    color: COLORS.kettleBlack,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.2),
    fontWeight: "700",
  },
  webAvaiable: {
    color: COLORS.white,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.4),
    fontWeight: "400",
    marginLeft: taxiLinkFontSize(1),
  },
  mobAvailable: {
    color: COLORS.white,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.2),
    fontWeight: "400",
    // marginLeft: deviceWidth < 361 ? taxiLinkFontSize(0.5) : taxiLinkFontSize(1),
  },
  goBack: {
    width: isWeb ? taxiLinkFontSize(3) : taxiLinkFontSize(2),
    height: isWeb ? taxiLinkFontSize(2.4) : taxiLinkFontSize(1.4),
    tintColor: COLORS.white,
    marginEnd: isWeb ? taxiLinkFontSize(2) : taxiLinkFontSize(1),
  },
});
