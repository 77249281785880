import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Image,
} from "react-native";
import React, { useState } from "react";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { isMobile, isWeb } from "../utils/Sizes";
import { fontFamilies } from "../assets/fonts";

export default function ContactUS() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const validateEmail = (email) => {
    const v = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if(v){
        return true
      }else{
        return false
      }
  };

  const validateNum = (text) => {
    return text.replace(/[^0-9]/g, '')
  }

  const submit = ()=> {
    if (!name.trim()) {
      alert("Please enter your name!");
      return;
    }else if (!email.trim()) {
      alert("Please enter your email address!");
      return;
    }else if(!validateEmail(email)){
      alert("Please enter a valid email address!")
    }else if (!phone.trim()) {
      alert("Please enter your phone number");
      return;
    }else{
      alert("done")
  
      console.log("aaaaaaaa",validateEmail(email));
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headingText}>Kontakta oss</Text>
      </View>

      <View style={isWeb ? styles.mainBox : styles.mainBoxForMobile}>
        <View style={isWeb ? styles.leftBox : styles.leftBoxForMobile}>
          <View style={styles.innerLeftBox}>
            <View style={{ flex: isWeb && 2 }}>
              <Text style={styles.text1}>Kontakta oss</Text>
              <Text style={styles.descText}>
              Vi är här för att hjälpa dig! Om du har några frågor, förfrågningar eller feedback är du välkommen att kontakta vårt team. Vi strävar efter att ge dig den bästa möjliga servicen och ser fram emot att höra från dig.{" "}
              </Text>
            </View>

            <View style={styles.inputBox}>
              <View style={styles.textInput}>
                <TextInput
                  numberOfLines={2}
                  maxLength={40}
                  onChangeText={setName}
                  value={name}
                  keyboardType='default'
                  placeholder="Namn *"
                  placeholderTextColor={"grey"}
                  style={styles.input}
                />
              </View>

              <View style={styles.textInput}>
                <TextInput
                  numberOfLines={2}
                  maxLength={40}
                  onChangeText={setEmail}
                  value={email}
                  keyboardType="email-address"
                  placeholder="E-post"
                  placeholderTextColor={"grey"}
                  style={styles.input}
                />
              </View>

              <View style={styles.textInput}>
                <TextInput
                  numberOfLines={2}
                  maxLength={40}
                  onChangeText={(text)=> {
                    if(text.length <16){
                      setPhone(validateNum(text))
                    }
                  }}
                  value={phone}
                  keyboardType="number-pad"
                  
                  placeholder="Telefonnummer *"
                  placeholderTextColor={"grey"}
                  style={styles.input}
                />
              </View>
            </View>
            <View style={{ flex: 1, marginTop: taxiLinkFontSize(5) }}>
              <TouchableOpacity style={styles.btn} onPress={()=> submit()}>
                <Text style={styles.txt}>Skicka</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={styles.rightBox}>
          <View style={styles.innerrightBox}>
            <View style={[styles.one, {marginLeft: isMobile && -taxiLinkFontSize(2)}]}>
              <View style={styles.innerone}>
                <Image
                  source={isMobile ? images.whiteMail : images.mail}
                  style={[isWeb ? styles.img35 : styles.img28, {}]}
                />
              </View>
              <View style={styles.two}>
                <Text style={styles.email}>book.taxilink@gmail.com</Text>
              </View>
            </View>

            <View style={[styles.one, {}]}>
              <View style={styles.innerone}>
                <Image
                  source={isMobile ? images.whiteCall : images.call1}
                  style={[isWeb ? styles.img35 : styles.img28, {marginLeft: isMobile && taxiLinkFontSize(4)}]}
                />
              </View>
              <TouchableOpacity style={styles.two} onPress={()=>{
                Platform.OS==="ios"? Linking.openURL(`telprompt:${"073-645 76 90"}`):Linking.openURL(`tel:${"073-645 76 90"}`)
              }}>
                <Text style={styles.mobile}>073-645 76 90</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb ? taxiLinkFontSize(100) : taxiLinkFontSize(87.2),
    backgroundColor: COLORS.pureBlack,
  },
  header: {
    width: "100%",
    height: isWeb && "20%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.blackBg,
    paddingTop: isWeb && taxiLinkFontSize(2),
    paddingBottom: isMobile && taxiLinkFontSize(1),
  },
  headingText: {
    color: COLORS.primary,
    fontFamily: fontFamilies.Popins,
    fontSize: isWeb ? taxiLinkFontSize(5.1) : taxiLinkFontSize(3.2),
    fontWeight: "800",
  },
  btn: {
    width: "100%",
    height: taxiLinkFontSize(5.9),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.primary,
  },
  txt: {
    color: isWeb ? COLORS.kettleBlack : COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.8),
    fontWeight: "700",
  },
  img35: {
    width: taxiLinkFontSize(3.5),
    height: taxiLinkFontSize(3.5),
    resizeMode: "contain",
  },
  img28: {
    width: taxiLinkFontSize(2.8),
    height: taxiLinkFontSize(2.8),
    resizeMode: "contain",
  },
  mainBox: {
    width: "100%",
    height: "80%",
    flexDirection: "row",
  },
  mainBoxForMobile: {
    width: "100%",
    height: "80%",
  },
  leftBox: {
    width: "67%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  leftBoxForMobile: {
    width: "100%",
    height: "85%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  rightBox: {
    width: isMobile ? "80%" : "33%",
    height: isWeb ? "100%" : "15%",
    justifyContent: "center" ,
    alignItems: isWeb ? "flex-end" : 'center',
    backgroundColor: isMobile ? "black" : COLORS.primary,
    marginTop: isMobile && 80,
    alignSelf: isMobile && 'center',
  },
  innerLeftBox: {
    width: isMobile ? "85%" : "60%",
    height: "70%",
  },
  text1: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(5.4) : taxiLinkFontSize(3.2),
    fontWeight: "400",
  },
  textInput: {
    width: "100%",
    height: taxiLinkFontSize(6.4),
    borderWidth: 1,
    borderColor: "white",
    justifyContent: "center",
    paddingLeft: 5,
    borderRadius: taxiLinkFontSize(2.7),
    marginBottom: isWeb && taxiLinkFontSize(2.5),
    marginTop: isMobile && taxiLinkFontSize(3)
  },
  inputBox: {
    flex: isWeb && 3,
    justifyContent: "space-evenly",
    // marginTop: taxiLinkFontSize(2)
  },
  input: {
    marginLeft: taxiLinkFontSize(1),
    marginRight: taxiLinkFontSize(1),
    color: COLORS.white,
    outlineStyle: "none",
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.6),
    fontWeight: "400",
    height: '80%'
  },
  descText: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(1.8) : taxiLinkFontSize(1.6),
    fontWeight: "400",
    marginTop: isWeb? "2%" : taxiLinkFontSize(2),
  },
  innerrightBox: {
    width: isWeb? "80%" : "100%",
    height: isMobile ? "100%" : "23%",
    justifyContent: isMobile ? 'space-between' : null,
    flexDirection: isMobile ? "row" : "column",
  },
  one: {
    flexDirection: "row",
    width: isMobile ? "50%" : null,
    height: isWeb ? "50%" : "100%",
    marginTop: isWeb ? "5%" : null,
  },
  innerone: {
    flex: isWeb && 1,
    justifyContent: "center",
    alignItems: "center",
  },
  two: {
    flex: isWeb && 5,
    justifyContent: "space-evenly",
  },
  email: {
    color: isWeb ? COLORS.lightBlack : COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(2.6) : taxiLinkFontSize(1.4),
    fontWeight: isWeb ? "400" : "700",
    marginLeft: taxiLinkFontSize(1)
  },
  mobile: {
    color: isWeb ? COLORS.lightBlack : COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb ? taxiLinkFontSize(2.4) : taxiLinkFontSize(1.3),
    fontWeight: "400",
    marginLeft: taxiLinkFontSize(1),
  },
});
