import { StyleSheet, Text, View, Image } from "react-native";
import React from "react";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { Dimensions } from "react-native";
import { isMobile, isWeb } from "../utils/Sizes";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { fontFamilies } from "../assets/fonts";

export default function AboutTexiLink() {
  const deviceWidth = Dimensions.get("window").width;
  // console.log(deviceWidth);

  const customCard = (no, label, dec) => {
    return(
        <View style={[styles.cardBox(deviceWidth), { marginRight: isWeb? 20 : 0 }]}>
            <Text style={[styles.oneText, {fontSize: isWeb? taxiLinkFontSize(7.7) : taxiLinkFontSize(4.2)}]}>{no}</Text>
            <Text style={[styles.twoText, {fontSize: isWeb? taxiLinkFontSize(3) : taxiLinkFontSize(2)}]}>{label}</Text>
            <Text style={[styles.threeText, {fontSize: isWeb? taxiLinkFontSize(2) : taxiLinkFontSize(1.4)}]}>{dec}</Text>
          </View>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.outerBox}>
        <Text style={isWeb ? styles.webAboutTaxi : styles.mobAboutTaxi}>
          Om Taxi Link
        </Text>
        <View style={{ flexDirection: "row",justifyContent: isWeb? "center" : 'space-evenly', width: "100%", }}>
          {customCard("01.", "Enkel bokning", "Användare kan bekvämt boka en taxi från bekvämligheten av sina hem eller var som helst med tillgång till internet. ")}
          {customCard("02.","Säkra turer","Njut av säkra turer med oss ​​– utbildad förare, pålitligt fordon, din säkerhet är vårt åtagande.")}
        </View>
        <View style={{ flexDirection: "row", justifyContent: isWeb? "center" : 'space-evenly', width: "100%", marginTop: isWeb? 25: 30, }} >
          {customCard("03.", "Överkomliga priser", "Prisvärda resor utan kompromisser – komfort, säkerhet och besparingar på varje resa.")}
          {customCard("04.","24/7 tjänst", "24/7 tjänst för dina åkbehov, vilket garanterar bekvämlighet och sinnesfrid.")}
        </View>
      </View>
      <View style={styles.lowerBox}>
        <Image
          source={images.bar}
          style={{ width: "100%", height: "100%", resizeMode: 'stretch' }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: isWeb? taxiLinkFontSize(133) : taxiLinkFontSize(89.9),
    backgroundColor: COLORS.blackBg,
  },
  outerBox: {
    width: "100%",
    height: "90%",
    backgroundColor: COLORS.blackBg,
    // justifyContent: 'center',
    alignItems: "center",
  },
  webAboutTaxi: {
    color: COLORS.primary,
    fontFamily: fontFamilies.Popins,
    fontSize: taxiLinkFontSize(5.1),
    fontWeight: "800",
    marginTop: taxiLinkFontSize(4),
    marginBottom: taxiLinkFontSize(4),
  },
  mobAboutTaxi: {
    color: COLORS.primary,
    fontFamily: fontFamilies.Popins,
    fontSize: taxiLinkFontSize(3.2),
    fontWeight: "800",
    marginTop: taxiLinkFontSize(4),
    marginBottom: taxiLinkFontSize(4),
  },
  lowerBox: {
    width: "100%",
    height: isWeb? taxiLinkFontSize(11.1) : taxiLinkFontSize(7.6),
  },
  innerBox: {
    width: "70%",
    height: "100%",
    alignItems: "center",
  },

  cardBox: (deviceWidth) => ({
    // justifyContent: "center",
    // alignItems: 'center',
    alignSelf: "center",
    borderWidth: taxiLinkFontSize(0.05),
    borderColor: COLORS.primary,
    borderRadius: taxiLinkFontSize(0.5),
    padding: isWeb? taxiLinkFontSize(2.5) : deviceWidth < 391 ? taxiLinkFontSize(1.2) : taxiLinkFontSize(2),
    width: isWeb ? "21%" : "42%",
    height: isWeb ? taxiLinkFontSize(38.3) : taxiLinkFontSize(26.7),
  }),
  innerCardBox: {
    width: "90%",
    height: "85%",
    borderWidth: 0.5,
    borderColor: COLORS.primary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
  },

  one: {
    width: "80%",
    height: "30%",
    justifyContent: "center",
  },
  oneText: {
    color: COLORS.primary,
    fontFamily: fontFamilies.Popins,
    fontWeight: "400",
    // lineHeight: 118,
  },
  two: {
    width: "80%",
    height: "20%",
    justifyContent: "center",
  },
  twoText: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "500",
    paddingTop: isMobile && taxiLinkFontSize(1),
  },
  three: {
    width: "80%",
    height: "40%",
  },
  threeText: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "500",
    paddingVertical: isWeb? taxiLinkFontSize(1) : taxiLinkFontSize(2),
    marginTop: isWeb? taxiLinkFontSize(4) : 0,
    height: isMobile && taxiLinkFontSize(16)
  },
});
