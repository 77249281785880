import { firbaseInitailize } from '../../firebaseConfig';
import { getDatabase, ref, set, get, child, update, push } from "firebase/database";


export const writeData = (data, tableName) => {
  const db = getDatabase();
  const postListRef = ref(db, tableName);
  const newPostRef = push(postListRef);
  set(newPostRef, 
   data
  );

  console.log(JSON.stringify(data) + tableName + "Successfully Stored");
};

export const addData = (name) => {
  const db = getDatabase();
  const postListRef = ref(db, 'name');
  const newPostRef = push(postListRef);
  set(newPostRef, {
    name:name
  });
}



export const fetchData = async () => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `name`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(JSON.stringify(snapshot));
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
};

