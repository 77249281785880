import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Platform,
  Image,
  ImageBackground,
  StatusBar,
  useWindowDimensions,
  SafeAreaView,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Modal,
  Linking,
} from "react-native";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import MobileHeader from "./MobileHeader";
import "react-native-gesture-handler";
import NavBar from "./NavBar";
import { isMobile, isWeb } from "../utils/Sizes";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { Epilogue, InterRegular, PoppinsBold } from "../assets/fonts";

export default function FirstComponent(props) {
  const {
    firstComponentRef,
    bookNowRef,
    contactUsRef,
    aboutUsRef,
    navigation,
    route,
  } = props;

  const [isMenuSidebarVisible, setMenuSidebarVisible] = useState(false);

  const scrollToComponent = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    // if (isWeb) {
    // } else {
    //   if (scrollViewRef.current && ref.current) {
    //     scrollViewRef.current.scrollTo({
    //       y: ref.current.offsetTop,
    //       animated: true,
    //     });
    //   }
    // }
  };

  const customButton = (label, onPress) => {
    return (
      <TouchableOpacity style={styles.sidebarBtn} onPress={onPress}>
        <Text style={styles.sidebarLabel}>{label}</Text>
      </TouchableOpacity>
    );
  };

  const onSidebarBtnPress = (ref) => {
    setMenuSidebarVisible(false);
    setTimeout(() => {
      scrollToComponent(ref);
    }, 500);
  };

  return (
    <SafeAreaView style={isWeb ? styles.container : styles.mobContainer}>
      <StatusBar />
      <View style={{ width: "100%", height: "100%" }}>
        <ImageBackground
          source={images.texi}
          style={isWeb ? styles.mainBox : styles.mobMainBox}
        >
          <View style={styles.overlay}>
            <MobileHeader navigation={navigation} route={route} />
            <View style={styles.one}>
              <View style={isWeb ? styles.webLogo : styles.mobLogo}>
                <Image
                  source={images.logo}
                  style={{
                    ...Platform.select({
                      web: { width: "100%", height: "100%" },
                      android: {
                        width: "100%",
                        height: "100%",
                        resizeMode: "contain",
                      },
                    }),
                  }}
                />
              </View>

              {isMobile ? (
                <View
                  style={{
                    marginTop: 12,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={images.call}
                    style={{
                      resizeMode: "contain",
                      width: 17,
                      height: 17,
                    }}
                  />
                  <View style={{ marginLeft: 10 }}>
                    <TouchableOpacity onPress={() => {
                      Platform.OS==="ios"? Linking.openURL(`telprompt:${"073-645 76 90"}`):Linking.openURL(`tel:${"073-645 76 90"}`)
                      // Linking.openURL(`tel:${phoneNumber}`)
                    }}>
                      <Text
                        style={{
                          color: COLORS.white,
                          fontSize: 12,
                          fontWeight: "600",
                        }}
                      >
                       073-645 76 90
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{ color: COLORS.white, fontSize: 8, marginTop: 4 }}
                    >
                      Endast tillgängligt på aktiva timmar{" "}
                    </Text>
                  </View>
                </View>
              ) : null}

              {isMobile && (
                <TouchableOpacity
                  style={styles.menuBtn}
                  onPress={() => setMenuSidebarVisible(true)}
                >
                  <Image source={images.menu} style={styles.menu} />
                </TouchableOpacity>
              )}

              {Platform.OS != "web" ? (
                <View
                  style={{
                    width: 128,
                    height: 30,
                    marginTop: 20,
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "25%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={images.call}
                      style={{ width: 25, height: 25, resizeMode: "contain" }}
                    />
                  </View>
                  <TouchableOpacity onPress={()=>{
                   Platform.OS==="ios"? Linking.openURL(`telprompt:${phoneNumber}`):Linking.openURL(`tel:${phoneNumber}`)
                  }}
                    style={{
                      width: "75%",
                      height: "100%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Text style={{ fontSize: 12, color: "#fff" }}>
                      073-645 76 90
                    </Text>
                    <Text style={{ fontSize: 9, color: "#fff" }}>
                      Availale on active hours only
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : null}
            </View>

            {isWeb ? (
              <View style={{ marginTop: "2%" }}>
                <NavBar
                  onHomePress={() => {
                    scrollToComponent(firstComponentRef);
                  }}
                  onBookNowPress={() => {
                    scrollToComponent(bookNowRef);
                  }}
                  onAboutUsPress={() => {
                    scrollToComponent(aboutUsRef);
                  }}
                  onContactUsPress={() => {
                    scrollToComponent(contactUsRef);
                  }}
                />
              </View>
            ) : null}

            <View style={isWeb ? styles.two : styles.mobTwo}>
              <View
                style={{
                  width: "100%",
                  height: "90%",
                  marginTop: isWeb ? "5%" : 10,
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Text style={isWeb ? styles.webHireAText : styles.mobHireAText}>
                  {" "}
                  {"Boka en taxi direkt och bekvämt"}
                </Text>
                <Text style={isWeb ? styles.webBookARide : styles.mobBookARide}>
                  Boka en tur med oss ​​idag för att få en oförglömlig bra
                  upplevelse och nå din destination i tid
                </Text>
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>
      <Modal
        visible={isMenuSidebarVisible}
        animationType="fade"
        transparent
        onRequestClose={() => { }}
      >
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.crossBtn}
            onPress={() => setMenuSidebarVisible(false)}
          >
            <Image source={images.cross} style={styles.cross} />
          </TouchableOpacity>
          <View style={styles.viewModal}>
            {customButton("Hem", () => onSidebarBtnPress(firstComponentRef))}
            {customButton("Boka nu", () => onSidebarBtnPress(bookNowRef))}
            {customButton("Om oss", () => onSidebarBtnPress(aboutUsRef))}
            {customButton("Kontakta oss", () =>
              onSidebarBtnPress(contactUsRef)
            )}

            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://dashboard.taxilink.se/");
              }}
              style={styles.driverLoginBtn}
            >
              <Text style={styles.driverLoginLabel}>
                Inloggning för drivrutinen --
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: taxiLinkFontSize(70.7),
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  mobContainer: {
    width: "100%",
    height: taxiLinkFontSize(35.4),
  },

  mainBox: {
    width: "100%",
    height: "100%",
    // backgroundColor: 'gray',
  },

  mobMainBox: {
    width: "100%",
    height: 354,
  },
  one: {
    width: "100%",
    // height: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  two: {
    width: "100%",
    height: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  mobTwo: {
    marginTop: 15,
  },

  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust the opacity (last value) to change the dullness
  },

  br: {
    height: 12,
  },
  btn: {
    backgroundColor: "#222",
    padding: 10,
  },
  btnText: {
    color: "#fff",
  },
  webLogo: {
    width: taxiLinkFontSize(24),
    height: taxiLinkFontSize(10.6),
    top: -taxiLinkFontSize(3.8),
  },
  mobLogo: {
    width: taxiLinkFontSize(12.8),
    height: taxiLinkFontSize(5.7),
    marginTop: taxiLinkFontSize(2),
  },
  box: {
    ...Platform.select({
      ios: {
        width: 150,
        height: 150,
      },
      android: {
        width: 150,
        height: 150,
        alignSelf: "center",
        marginTop: "50%",
      },
      web: {
        width: 250,
        height: 150,
        alignSelf: "center",
      },
    }),
  },
  webHireAText: {
    color: COLORS.white,
    fontFamily: PoppinsBold,
    fontSize: taxiLinkFontSize(5.5),
    fontWeight: "800",
    textAlign: "center",
    width: taxiLinkFontSize(70.8),
    marginTop: taxiLinkFontSize(1.5),
    lineHeight: taxiLinkFontSize(6.7),
  },
  mobHireAText: {
    fontSize: taxiLinkFontSize(3.2),
    fontFamily: PoppinsBold,
    fontWeight: "800",
    color: COLORS.white,
    textAlign: "center",
    width: taxiLinkFontSize(33.5),
    lineHeight: taxiLinkFontSize(4.2),
  },
  webBookARide: {
    color: COLORS.white,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(2),
    textAlign: "center",
    fontWeight: "400",
    lineHeight: taxiLinkFontSize(3),
    width: taxiLinkFontSize(50),
  },
  mobBookARide: {
    color: COLORS.white,
    fontSize: taxiLinkFontSize(1.4),
    fontFamily: InterRegular,
    width: taxiLinkFontSize(22.6),
    textAlign: "center",
    marginTop: taxiLinkFontSize(1.5),
    lineHeight: taxiLinkFontSize(1.7),
  },
  menuBtn: {
    position: "absolute",
    top: 40,
    right: 20,
  },
  menu: {
    width: taxiLinkFontSize(2.5),
    height: taxiLinkFontSize(2.1),
    resizeMode: "contain",
  },
  modalView: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.blackBg,
  },
  viewModal: {
    marginLeft: taxiLinkFontSize(3.4),
    marginRight: taxiLinkFontSize(3.4),
    marginTop: taxiLinkFontSize(3.4),
  },
  sidebarBtn: {
    padding: taxiLinkFontSize(1),
    marginBottom: taxiLinkFontSize(2),
  },
  sidebarLabel: {
    color: COLORS.white,
    fontFamily: Epilogue,
    fontSize: taxiLinkFontSize(2.2),
    fontWeight: "400",
  },
  crossBtn: {
    padding: taxiLinkFontSize(3),
    alignItems: "flex-end",
  },
  cross: {
    width: taxiLinkFontSize(1.8),
    height: taxiLinkFontSize(1.8),
  },
  driverLoginBtn: {
    // width: taxiLinkFontSize(13.8),
    height: taxiLinkFontSize(5),
    backgroundColor: COLORS.white,
    alignItems: "center",
    borderWidth: 1,
    borderColor: COLORS.pureBlack,
    justifyContent: "center",
    marginHorizontal: taxiLinkFontSize(1),
    marginTop: taxiLinkFontSize(3),
  },
  driverLoginLabel: {
    color: COLORS.blackBg,
    fontFamily: InterRegular,
    fontSize: taxiLinkFontSize(1.6),
    fontWeight: "700",
  },
});
