import {
  StyleSheet,
  Text,
  View,
  Image,
  platform,
  TextInput,
  ScrollView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { images } from "../assets";
import { COLORS, SIZES } from "../utils";
import { TouchableOpacity } from "react-native";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import Modal from "react-native-modal";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import InputField from "./InputField";
import { firbaseInitailize } from "../../firebaseConfig";
import { writeData, fetchData, addData } from "../firebase methods/AllMethods";
import { isMobile, isWeb } from "../utils/Sizes";
import { fontFamilies } from "../assets/fonts";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import moment from "moment";

export default function BookingSection({ navigation }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSmallModalVisible, setIsSmallModalVisible] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setTimePickerOpen] = useState(false);
  const [isYesNoModalVisible, setYesNoModalVisible] = useState(false);
  const [modalType, setModalType] = useState("");

  const [from, setFrom] = useState("");
  const [destination, setDestination] = useState("");
  const [time, setTime] = useState("");
  const [pickDate, setPickDate] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [personsCount, setPersonsCount] = useState("");
  const [babySeat, setBabySeat] = useState("Nej");
  const [isAnimal, setIsAnimal] = useState("Nej");

  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let countdownInterval;
    if (isCountdownActive) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [isCountdownActive]);

  useEffect(() => {
    if (timer === 0) {
      setIsCountdownActive(false);
    }
  }, [timer]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const result = firbaseInitailize;
  }, []);

  const validateEmail = (email) => {
    const v = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (v) {
      return true;
    } else {
      return false;
    }
  };

  const validateNum = (text) => {
    return text.replace(/[^0-9]/g, "");
  };

  const toggleModal = () => {
    if (!from.trim()) {
      alert("Please enter the leaving from!");
      return;
    } else if (!destination.trim()) {
      alert("Please enter the destination!");
      return;
    } else if (!time.trim()) {
      alert("Please select time!");
      return;
    } else if (!pickDate.trim()) {
      alert("Please select date!");
      return;
    } else {
      // bookingData();
      fetchData();
      setModalVisible(true);
    }
  };

  const submitData = () => {
    if (!name.trim()) {
      alert("Please enter your name!");
      return;
    } else if (!phoneNo.trim()) {
      alert("Please enter your phone number!");
      return;
    } else if (!email.trim()) {
      alert("Please enter your email!");
      return;
    } else if (!validateEmail(email)) {
      alert("Please enter a valid email address!");
    } else if (!personsCount.trim()) {
      alert("Please enter the no of persons!");
      return;
    } else if (!babySeat.trim()) {
      alert("Please choose a baby seat option!");
      return;
    } else if (!isAnimal.trim()) {
      alert("Please choose an animals option!");
      return;
    } else {
      bookingData();
      setModalVisible(false);
      setIsSmallModalVisible(true);
      emptyTheFields();
    }
  };
  const emptyTheFields = () => {
    setFrom("");
    setDestination("");
    setTime("");
    setPickDate("");
    setName("");
    setPhoneNo("");
    setEmail("");
    setPersonsCount("");
    setBabySeat("");
    setIsAnimal("");
  };

  const writeUserData = () => {
    console.log("Inside WriteuserData");
    const data = {
      username: "Sufyan Tariq",
      email: "Sufyan@gmail.com",
    };
    writeData(data, "users");
  };

  const bookingData = () => {
    const data = {
      leavingFrom: from,
      destination: destination,
      time: time,
      date: pickDate,
      name: name,
      phoneNo: phoneNo,
      email: email,
      personsCount: personsCount,
      babySeat: babySeat === "Ja" ? true : false,
      isAnimal: isAnimal === "Ja" ? true : false,
      status: "pending",
      readBit: false,
      orderAt: moment().format("YYYY-MM-DD HH:mm:ss")
    };
    console.log("dataBody", data);
    writeData(data, "booking");
    setTimer(60)
    setIsCountdownActive(true);
  };

  console.log(isMobile);

  const getParsedDate = (strDate) => {
    var date = new Date(strDate);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "-" + mm + "-" + yyyy;
    return date.toString();
  };

  const today = new Date();
  const DAY = today.getDate();
  const MONTH = today.getMonth();
  const YEAR = today.getFullYear();
  console.log("day", DAY, MONTH, YEAR);
  console.log("googleplaces: ", from);

  return (
    <View style={isWeb ? styles.container : styles.mobContainer}>
      {/* <ScrollView>
        <GooglePlacesAutocomplete
          placeholder="Search destination"
          minLength={2}
          onPress={(data, details = null) => {
            // 'details' is provided when fetchDetails = true
            console.log(data, details);
          }}
          query={{
            key: "AIzaSyDZEL-45LAtVHdJEDmbs0LpR-nLwHT8gcM",
            language: "en",
          }}
        />
      </ScrollView> */}
      <View style={isWeb ? styles.mainBox : styles.mobMainBox}>
        <View style={isWeb ? styles.leftBox : styles.mobLeftbox}>
          <View style={{ width: "80%", height: isWeb ? "50%" : "" }}>
            <View style={styles.text1}>
              <Text style={isWeb ? styles.webBookATexi : styles.mobBookATexi}>
                Boka en taxi nu
              </Text>
            </View>
            <View style={isWeb ? styles.text : {}}>
              <Text style={isWeb ? styles.webOurTaxi : styles.mobOurTaxi}>
                Vår taxi förpliktar sig att göra dina resor unika genom att bäst möta dina behov.
              </Text>
            </View>
          </View>
        </View>

        <View style={isWeb ? styles.webRightBox : styles.mobRightBox}>
          <View style={isWeb ? styles.webInner : styles.mobInner}>
            <View
              style={
                isWeb
                  ? { width: "100%", height: "70%", alignItems: "center" }
                  : { width: "100%", alignItems: "center" }
              }
            >
              <View
                style={
                  isWeb
                    ? { width: "100%", height: "50%", flexDirection: "row" }
                    : { width: "100%" }
                }
              >
                <InputField
                  label={"Avgår från"}
                  iconSource={images.location}
                  startIconStyle={styles.img21}
                  placeholderText={"Ange en hämtningsplats"}
                  value={from}
                  googlePlaces={true}
                  onChangeText={(v) => setFrom(v)}
                />

                <View style={{ width: taxiLinkFontSize(2.5) }}></View>

                <InputField
                  label={"Välj din destination"}
                  iconSource={images.location}
                  startIconStyle={styles.img21}
                  placeholderText={"Ange en avlämningsplats"}
                  value={destination}
                  googlePlaces={true}
                  onChangeText={(v) => setDestination(v)}
                />
              </View>

              <View
                style={
                  isWeb
                    ? { width: "100%", height: "50%", flexDirection: "row" }
                    : { width: "100%" }
                }
              >
                <InputField
                  label={"Välj en tid"}
                  iconSource={images.clock1}
                  startIconStyle={styles.img21}
                  placeholderText={"Välj tid att boka"}
                  endIcon={images.down}
                  onPress={() => setTimePickerOpen(true)}
                  value={time}
                  onChangeText={(v) => setTime(v)}
                />

                <View style={{ width: taxiLinkFontSize(2.5) }}></View>

                <InputField
                  label={"Välj ett datum"}
                  iconSource={images.calendar}
                  startIconStyle={styles.img19}
                  placeholderText={"Välj ett datum"}
                  endIcon={images.down}
                  onPress={() => {
                    setPickDate("");
                    setDatePickerOpen(true);
                    // navigation.navigate("GooglePlaces")
                  }}
                  value={pickDate}
                  onChangeText={(v) => setPickDate(v)}
                />
              </View>
            </View>

            <TouchableOpacity
              onPress={() => toggleModal()}
              style={isWeb ? styles.webBookNowBtn : styles.mobBookNowBtn}
            >
              <Text style={{ fontSize: SIZES.h1 }}>Boka nu</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <Modal isVisible={isModalVisible} transparent>
        <ScrollView
          style={isWeb ? styles.webModalStyle : styles.mobModalStyle}
          contentContainerStyle={{ alignItems: isWeb && "center" }}
        >
          <View style={isWeb ? styles.crossView : styles.mobCrossView}>
            <TouchableOpacity onPress={() => setModalVisible(false)}>
              <Image source={images.cross} style={{ width: 15, height: 15 }} />
            </TouchableOpacity>
          </View>

          <View style={{}}>
            <View style={{}}>
              <Text style={isWeb ? styles.txt1 : styles.mobTxt1}>
                Vi behöver lite mer information för att boka din resa
              </Text>
            </View>

            <View
              style={
                isWeb
                  ? { flexDirection: "row", marginTop: taxiLinkFontSize(5) }
                  : { width: "100%" }
              }
            >
              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"Namn"}
                  iconSource={images.user}
                  startIconStyle={styles.img16}
                  placeholderText={"Namn"}
                  value={name}
                  onChangeText={(v) => setName(v)}
                />
              </View>

              <View
                style={{
                  width: isWeb ? taxiLinkFontSize(5.4) : taxiLinkFontSize(2.5),
                }}
              ></View>

              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"Telefonnummer"}
                  iconSource={images.simpleCall}
                  startIconStyle={styles.img18}
                  placeholderText={"Skriv in telefonnummer"}
                  value={phoneNo}
                  onChangeText={(v) => {
                    if (v.length < 16) {
                      setPhoneNo(validateNum(v));
                    }
                  }}
                />
              </View>
            </View>

            <View
              style={
                isWeb
                  ? { flexDirection: "row", marginTop: taxiLinkFontSize(5) }
                  : { width: "100%" }
              }
            >
              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"E-post"}
                  iconSource={images.mail}
                  startIconStyle={styles.img16}
                  placeholderText={"Ange din giltiga e-postadress"}
                  value={email}
                  onChangeText={(v) => setEmail(v)}
                />
              </View>

              <View
                style={{
                  width: isWeb ? taxiLinkFontSize(5.4) : taxiLinkFontSize(2.5),
                }}
              ></View>
              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"Antal personer"}
                  iconSource={images.userGroup}
                  startIconStyle={styles.imgW21H16}
                  placeholderText={"Ange antal.personer"}
                  value={personsCount}
                  onChangeText={(v) => {
                    if (v < 7) {
                      setPersonsCount(validateNum(v));
                    }
                  }}
                />
              </View>
            </View>

            <View
              style={
                isWeb
                  ? {
                    width: "100%",
                    flexDirection: "row",
                    marginTop: taxiLinkFontSize(5),
                  }
                  : { width: "100%" }
              }
            >
              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"Barnsäte"}
                  iconSource={images.baby}
                  startIconStyle={styles.img19}
                  placeholderText={"Nej"}
                  endIcon={images.down}
                  onPress={() => {
                    setModalType("baby");
                    setYesNoModalVisible(true);
                  }}
                  value={babySeat}
                  onChangeText={(v) => setBabySeat(v)}
                />
              </View>

              <View
                style={{
                  width: isWeb ? taxiLinkFontSize(5.4) : taxiLinkFontSize(2.5),
                }}
              ></View>

              <View style={{ width: isWeb ? "50%" : "100%" }}>
                <InputField
                  label={"Finns det något djur?"}
                  iconSource={images.animal}
                  startIconStyle={styles.img16}
                  placeholderText={"Nej"}
                  endIcon={images.down}
                  onPress={() => {
                    setModalType("animal");
                    setYesNoModalVisible(true);
                  }}
                  value={isAnimal}
                  onChangeText={(v) => setIsAnimal(v)}
                />
              </View>
            </View>
            <View
              style={{
                height: taxiLinkFontSize(6.9),
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isMobile ? taxiLinkFontSize(5) : taxiLinkFontSize(8),
                marginBottom: isWeb ? 0 : taxiLinkFontSize(2),
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  submitData();
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: COLORS.primary,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: COLORS.kettleBlack,
                    fontFamily: fontFamilies.InterRegular,
                    fontWeight: "500",
                    fontSize: taxiLinkFontSize(2.4),
                  }}
                >
                  Fortsätta
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Modal>

      <Modal isVisible={isSmallModalVisible} transparent>
        <View
          style={{
            width: isWeb ? "50%" : "90%",
            // height: "70%",
            backgroundColor: COLORS.pureBlack,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "flex-end",
              padding: taxiLinkFontSize(3),
            }}
          >
            <TouchableOpacity onPress={() => setIsSmallModalVisible(false)}>
              <Image
                source={images.cross}
                style={isWeb ? styles.imgW20H15 : styles.imgW21H20}
              />
            </TouchableOpacity>
          </View>

          <View
            style={{
              width: "90%",
              height: "80%",
              backgroundColor: "black",
              alignSelf: "center",
            }}
          >
            <View
              style={{ width: "100%", height: "60%", alignItems: "center" }}
            >
              <Image source={images.roundMail} style={styles.img56} />
              <View style={{ marginTop: "1%", alignItems: "center" }}>
                <Text
                  style={[
                    styles.smHeading,
                    {
                      fontSize: isWeb
                        ? taxiLinkFontSize(2.2)
                        : taxiLinkFontSize(2),
                    },
                  ]}
                >
                  Kolla din mail
                </Text>
                <Text
                  style={[
                    styles.smDescription,
                    {
                      fontSize: isWeb
                        ? taxiLinkFontSize(1.6)
                        : taxiLinkFontSize(1.4),
                    },
                  ]}
                >
                  Vi har skickat ett mejl till dig. Bekräfta din bokning!
                </Text>
                <Text
                  style={[
                    styles.smDescription,
                    {
                      fontSize: isWeb
                        ? taxiLinkFontSize(1.2)
                        : taxiLinkFontSize(1),
                    },
                  ]}
                >
                  Om du inte fick mejlet. Kontrollera din spam.
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "40%",
                alignItems: "center",
                marginTop: taxiLinkFontSize(1.2),
                marginBottom: taxiLinkFontSize(4.3),
              }}
            >
              {/* <TouchableOpacity
                disabled={timer !== 0 ? true : false}
                style={{
                  width: isWeb ? "70%" : "95%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: timer !== 0 ? COLORS.gray85 : COLORS.primary,
                }}
                onPress={() => {
                  // setIsSmallModalVisible(false)
                  setTimer(60);
                  setIsCountdownActive(true);
                }}
              >
                <Text
                  style={[
                    styles.smBtnText,
                    {
                      fontSize: isWeb
                        ? taxiLinkFontSize(1.4)
                        : taxiLinkFontSize(1.2),
                    },
                  ]}
                >
                  Har inte fått e-posten? Skicka om om{" "}
                  {timer !== 0 && formatTime(timer)}
                </Text>
              </TouchableOpacity> */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 5,
                  // width: isMobile && "56%",
                  height: 40,
                  alignItems: "center",
                  marginTop: "2%",
                  marginTop: taxiLinkFontSize(3.2),
                }}
              >
                <TouchableOpacity
                  style={{
                    // width: "20%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => setIsSmallModalVisible(false)}
                >
                  <Image
                    source={images.back}
                    style={{
                      width: taxiLinkFontSize(2),
                      height: taxiLinkFontSize(2),
                      resizeMode: "contain",
                      tintColor: COLORS.white,
                    }}
                  />
                </TouchableOpacity>
                <Text style={styles.smBackText}>Tillbaka till bokning</Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <Modal isVisible={isYesNoModalVisible} transparent>
        <View
          style={{
            width: isWeb ? "30%" : "90%",
            // height: "70%",
            backgroundColor: COLORS.pureBlack,
            alignSelf: "center",
            borderWidth: taxiLinkFontSize(0.02),
            borderColor: COLORS.white,
            borderRadius: taxiLinkFontSize(2),
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "flex-end",
              padding: taxiLinkFontSize(3),
            }}
          >
            <TouchableOpacity onPress={() => setYesNoModalVisible(false)}>
              <Image
                source={images.cross}
                style={[styles.img16, { tintColor: COLORS.white }]}
              />
            </TouchableOpacity>
          </View>

          <View
            style={{
              width: "90%",
              alignSelf: "center",
              padding: taxiLinkFontSize(2),
            }}
          >
            <Text
              style={styles.yesNoText}
              onPress={() => {
                if (modalType === "baby") {
                  setBabySeat("Ja");
                } else {
                  setIsAnimal("Ja");
                }
                setYesNoModalVisible(false);
              }}
            >
              Ja
            </Text>
            <Text
              style={styles.yesNoText}
              onPress={() => {
                if (modalType === "baby") {
                  setBabySeat("Nej");
                } else {
                  setIsAnimal("Nej");
                }
                setYesNoModalVisible(false);
              }}
            >
              Nej
            </Text>
          </View>
        </View>
      </Modal>

      <DatePickerModal
        locale="en"
        mode="single"
        validRange={{
          startDate: new Date(YEAR, MONTH, DAY),
          endDate: undefined,
        }}
        // startDate={"Fri Nov 17 2023 23:59:59 GM"}
        visible={isDatePickerOpen}
        onDismiss={() => setDatePickerOpen(false)}
        date={pickDate}
        onChange={(params) => {
          console.log(
            "datesString: ",
            params.date,
            moment().format("DD-MM-YYYY")
          );
          if (params?.date) {
            setPickDate(getParsedDate(params.date));
          } else {
            setPickDate(moment().format("DD-MM-YYYY"));
          }
          setDatePickerOpen(false)

        }}
        onConfirm={(params) => {
          console.log(
            "datesString: ",
            params.date,
            moment().format("DD-MM-YYYY")
          );
          setDatePickerOpen(false);
          if (params?.date) {
            setPickDate(getParsedDate(params.date));
          } else {
            setPickDate(moment().format("DD-MM-YYYY"));
          }
        }}
      />
      <TimePickerModal
        visible={isTimePickerOpen}
        onDismiss={() => setTimePickerOpen(false)}
        onConfirm={({ hours, minutes }) => {
          setTimePickerOpen(false);
          console.log({ hours, minutes });
          setTime(hours + ":" + minutes);
        }}
        hours={12}
        minutes={14}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: taxiLinkFontSize(76.8),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#151515",
  },
  mobContainer: {
    width: "100%",
    height: taxiLinkFontSize(116),
    alignItems: "center",
    backgroundColor: COLORS.blackBg,
  },
  mainBox: {
    width: "90%",
    height: "80%",
    flexDirection: "row",
  },
  mobMainBox: {
    width: "80%",
    height: "80%",
    alignItems: "center",
  },
  leftBox: {
    width: "30%",
    height: "100%",
    justifyContent: "center",
  },
  mobLeftbox: {
    marginTop: 35,
  },
  webRightBox: {
    width: "78%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  mobRightBox: {
    height: "100%",
    // justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  text: {
    width: "100%",
    height: "35%",
  },
  mobText: {},
  text1: {
    width: "100%",
    height: "65%",
  },
  webInner: {
    // width: "87%",
    height: "70%",
  },
  mobInner: {
    width: "100%",
    marginTop: 50,
  },
  webInputBox: {
    marginTop: 10,
    width: "90%",
    height: 60,
    borderRadius: 30,
    borderWidth: 0.7,
    borderColor: "white",
    flexDirection: "row",
  },
  mobInputBox: {
    marginTop: 20,
    width: "100%",
    height: 70,
    borderRadius: 30,
    borderWidth: 0.7,
    borderColor: "white",
    flexDirection: "row",
    alignSelf: "center",
  },
  webModalStyle: {
    // width: taxiLinkFontSize(83.2),
    backgroundColor: COLORS.blackBg,
    alignSelf: "center",
    paddingLeft: taxiLinkFontSize(7),
    paddingRight: taxiLinkFontSize(7),
    paddingBottom: taxiLinkFontSize(7),
    paddingTop: taxiLinkFontSize(2),
    margin: taxiLinkFontSize(0.5),
    width: "70%",
  },
  mobModalStyle: {
    // width: taxiLinkFontSize(83.2),
    height: "90%",
    backgroundColor: COLORS.blackBg,
    // alignSelf: "center",
    padding: taxiLinkFontSize(2),
    margin: taxiLinkFontSize(0.5),
  },
  crossView: {
    width: "100%",
    height: "10%",
    alignItems: "flex-end",
    padding: 8,
  },
  mobCrossView: {
    width: "100%",
    height: "5%",
    alignItems: "flex-end",
    padding: 8,
  },
  txt1: {
    fontSize: RFPercentage(2.2),
    // alignSelf: "center",
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "700",
    color: COLORS.white,
    // marginTop: taxiLinkFontSize(1),
  },
  mobTxt1: {
    color: COLORS.white,
    fontSize: taxiLinkFontSize(2.8),
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "700",
    textAlign: "center",
    marginHorizontal: taxiLinkFontSize(1),
    marginBottom: taxiLinkFontSize(4),
    // alignSelf: "center",
    // marginTop: taxiLinkFontSize(1),
  },
  webBookATexi: {
    color: COLORS.primary,
    fontSize: 55,
    fontWeight: "bold",
    fontFamily: fontFamilies.PoppinsBold,
    width: taxiLinkFontSize(26.5),
  },
  mobBookATexi: {
    color: COLORS.primary,
    fontFamily: fontFamilies.PoppinsBold,
    fontSize: taxiLinkFontSize(5.5),
    fontWeight: "700",
    lineHeight: taxiLinkFontSize(7.3),
    width: taxiLinkFontSize(26.5),
    marginTop: taxiLinkFontSize(2)
  },
  webInputMainBox: {
    width: "50%",
    height: "70%",
  },
  mobInputMainBox: {
    width: "100%",
    marginTop: 30,
  },
  webBookNowBtn: {
    width: taxiLinkFontSize(68.8),
    height: taxiLinkFontSize(6.9),
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: taxiLinkFontSize(3),
  },
  mobBookNowBtn: {
    width: "100%",
    height: 69,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 70,
  },
  webOurTaxi: {
    color: COLORS.white,
    fontSize: taxiLinkFontSize(2),
    fontWeight: "400",
    fontFamily: fontFamilies.InterRegular,
    lineHeight: taxiLinkFontSize(3),
    width: taxiLinkFontSize(30.4),
  },
  mobOurTaxi: {
    color: COLORS.white,
    fontSize: taxiLinkFontSize(2),
    fontWeight: "400",
    fontFamily: fontFamilies.InterRegular,
    marginTop: taxiLinkFontSize(1.5),
    lineHeight: taxiLinkFontSize(3),
  },
  img21: {
    width: taxiLinkFontSize(2.1),
    height: taxiLinkFontSize(2.1),
    resizeMode: "contain",
    tintColor: COLORS.primary,
  },
  img19: {
    width: taxiLinkFontSize(1.9),
    height: taxiLinkFontSize(1.9),
    resizeMode: "contain",
    tintColor: COLORS.primary,
  },
  img16: {
    width: taxiLinkFontSize(1.6),
    height: taxiLinkFontSize(1.6),
    resizeMode: "contain",
    tintColor: COLORS.primary,
  },
  img18: {
    width: taxiLinkFontSize(1.8),
    height: taxiLinkFontSize(1.8),
    resizeMode: "contain",
    tintColor: COLORS.primary,
  },
  imgW21H16: {
    width: taxiLinkFontSize(2.1),
    height: taxiLinkFontSize(1.6),
    resizeMode: "contain",
    tintColor: COLORS.primary,
  },
  imgW21H20: {
    width: taxiLinkFontSize(2.1),
    height: taxiLinkFontSize(2),
    resizeMode: "contain",
    tintColor: COLORS.white,
  },
  imgW20H15: {
    width: taxiLinkFontSize(2),
    height: taxiLinkFontSize(1.5),
    tintColor: COLORS.white,
  },
  img56: {
    width: taxiLinkFontSize(5.6),
    height: taxiLinkFontSize(5.6),
    resizeMode: "contain",
  },
  smHeading: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "700",
    lineHeight: 20,
    marginTop: taxiLinkFontSize(3.1),
  },
  smDescription: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "400",
    lineHeight: 20,
    marginTop: taxiLinkFontSize(1.2),
    paddingHorizontal: isWeb ? taxiLinkFontSize(8) : taxiLinkFontSize(3.5),
    textAlign: "center",
  },
  smBtnText: {
    color: COLORS.pureBlack,
    fontFamily: fontFamilies.InterRegular,
    fontWeight: "600",
    lineHeight: 20,
    textAlign: "center",
  },
  smBackText: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.4),
    fontWeight: "600",
    lineHeight: 20,
    textAlign: "center",
    marginLeft: isWeb ? taxiLinkFontSize(1.5) : taxiLinkFontSize(1),
  },
  yesNoText: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.8),
    fontWeight: "600",
    lineHeight: 20,
    padding: taxiLinkFontSize(2),
  },
});
