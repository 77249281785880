export const texi=require('./image/texi.jpg');
export const logo=require('./image/img1.png');
export const call=require('./image/call.png');
export const Vector=require('./image/Vector.png');
export const clock1=require('./image/clock1.png');
export const location=require('./image/location.png');
export const clock=require('./image/Group.png');
export const dropdown=require('./image/dropdown.png');
export const calendar=require('./image/calendar.png');
export const texiimg=require('./image/texiimg.png');
export const bar=require('./image/bar.png');
export const mail=require('./image/mail.png');
export const call1=require('./image/call1.png');
export const twitter=require('./image/twitter.png');
export const facebook=require('./image/facebook.png');
export const cross=require('./image/cross.png');
export const user=require('./image/user.png');
export const userGroup=require('./image/userGroup.png');
export const baby=require('./image/baby.png');
export const animal=require('./image/animal.png');
export const simpleCall=require('./image/simpleCall.png');
export const Email=require('./image/Email.png');
export const back=require('./image/back.png');
export const whiteMail=require('./image/whiteMail.png');
export const whiteCall=require('./image/whiteCall.png');
export const menu=require('./image/menu.png');
export const down=require('./image/down.png');
export const roundMail=require('./image/roundMail.png');
export const downArrow=require('./image/downArrow.png');
export const upArrow=require('./image/upArrow.png');
export const goBack=require('./image/goBack.png');









export default{
    logo,
    texi,
    call,
    clock,
    Vector,
    clock1,
    location,
    dropdown,
    calendar,
    texiimg,
    bar,
    mail,
    call1,
    twitter,
    facebook,
    cross,
    user,
    baby,
    userGroup,
    animal,
    simpleCall,
    Email,
    back,
    whiteCall,
    whiteMail,
    menu,
    down,
    roundMail,
    downArrow,
    upArrow,
    goBack,
}

        // "googleServicesFile": "./google-services.json",
