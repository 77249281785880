import { StyleSheet, Text, View, Platform } from 'react-native'
import React from 'react'
import Routs from './src/navigation/Routs'
import useCachedResources from './src/utils/useCachedResources'
import { enGB, registerTranslation } from 'react-native-paper-dates'

export default function App() {
  registerTranslation('en-GB', enGB)
  const isLoadingComplete = useCachedResources();
if(!isLoadingComplete) return null 
else {
  return (
    <View style={styles.container}>
      <Routs />
    </View>
  )
  }
}

const styles = StyleSheet.create({
  container:{
    width:'100%',
    height:'100%'
  }
})