import { StyleSheet, Text, TouchableOpacity, View, Image } from "react-native";
import React from "react";
import { images } from "../assets";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { COLORS } from "../utils";
import { fontFamilies } from "../assets/fonts";
export default function NavBar(props) {
  const {onHomePress, onBookNowPress, onAboutUsPress, onContactUsPress} = props;

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "71%",
          height: 35,
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: "85%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <TouchableOpacity onPress={onHomePress}><Text style={styles.labels}>Hem</Text></TouchableOpacity>
          <TouchableOpacity onPress={onBookNowPress}><Text style={styles.labels}>Boka nu</Text></TouchableOpacity>
          <TouchableOpacity onPress={onAboutUsPress}><Text style={styles.labels}>Om oss</Text></TouchableOpacity>
          <TouchableOpacity onPress={onContactUsPress}><Text style={styles.labels}>Kontakta oss</Text></TouchableOpacity>
        </View>
      </View>
      <View style={styles.greyBg}>
        <View style={styles.callView}>
          <Image source={images.call} style={styles.callIcon} />
        </View>
        <View
          style={{
            width: "80%",
            height: "80%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={styles.number}>073-645 76 90</Text>
          <Text style={styles.dec}>Endast tillgängligt på aktiva timmar</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: taxiLinkFontSize(77),
    height: taxiLinkFontSize(6.8),
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: "#FFCD03",
    borderRadius: taxiLinkFontSize(4.3),
  },
  greyBg: {
    width: "32%",
    alignItems: "center",
    borderTopRightRadius: taxiLinkFontSize(4.3),
    borderBottomRightRadius: taxiLinkFontSize(4.3),
    height: taxiLinkFontSize(6.8),
    // alignSelf: "center",
    backgroundColor: "#464546",
    flexDirection: "row",
  },
  callView: {
    width: taxiLinkFontSize(4.3),
    height: taxiLinkFontSize(4.3),
    borderRadius: taxiLinkFontSize(2.2),
    backgroundColor: COLORS.grey,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -taxiLinkFontSize(2.1),
  },
  callIcon: {
    width: taxiLinkFontSize(2.6),
    height: taxiLinkFontSize(2.6),
  },
  labels: {
    color: COLORS.pureBlack,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.6),
    fontWeight: '400',
  },
  number: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(2),
    fontWeight: '500',
    letterSpacing: '0.4',
  },
  dec: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1),
    fontWeight: '400',
  },
});
