import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { taxiLinkFontSize } from "../utils/responsiveDimensions";
import { COLORS } from "../utils";
import { fontFamilies } from "../assets/fonts";
import { isMobile, isWeb } from "../utils/Sizes";
import { usePlacesWidget } from "react-google-autocomplete";

const InputField = (props) => {
  const {
    label,
    showIcon = true,
    iconSource,
    placeholderText,
    value,
    endIcon,
    startIconStyle,
    onChangeText = () => {},
    onPress,
    editable,
    keyboardType,
    googlePlaces,
  } = props;

  const { ref, autocompleteRef } = usePlacesWidget({
    //apiKey:"AIzaSyDZEL-45LAtVHdJEDmbs0LpR-nLwHT8gcM",//old key
    apiKey:"AIzaSyC2H6mPpW6Id5ZNxnRXTPKCyhbVTA5_A_w",
    options:{types: ["(regions)"],componentRestrictions: { country: "se" }},
    onPlaceSelected: (place) => {
      console.log("selectedPlace ===>",place?.formatted_address);
      onChangeText(place?.formatted_address)
    }
  });

  return (
    <View style={[styles.container]}>
      {/* , { flex: googlePlaces && 1 } */}
      <Text style={styles.label}>{label}</Text>
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={endIcon ? 0 : 1}
        style={[styles.inputContainer]} //, { height: googlePlaces && "100%" }
      >
        <Image source={iconSource} style={[styles.icon, startIconStyle]} />
        {/* {googlePlaces && (
          <Autocomplete
            apiKey={"AIzaSyDZEL-45LAtVHdJEDmbs0LpR-nLwHT8gcM"}
            onPlaceSelected={(place) => {
              console.log("selectedPlace ===>", place);
            }}
          />
        )} */}
        <TextInput
        ref={googlePlaces && ref}
          editable={endIcon? false : true}
          //   editable={editable}
          numberOfLines={2}
          maxLength={40}
          value={value}
          onChangeText={(text) => {
            // googlePlaces ?
            // onChangeText()
            onChangeText(text);
          }}
          keyboardType={keyboardType}
          placeholder={placeholderText}
          placeholderTextColor={COLORS.gray85}
          style={[
            styles.input,
            { marginRight: endIcon ? 0 : taxiLinkFontSize(3) },
          ]}
        />
        {endIcon && <Image source={endIcon} style={styles.endIcon} />}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: isMobile ? taxiLinkFontSize(3) : 0,
  },
  label: {
    color: COLORS.white,
    fontFamily: fontFamilies.InterRegular,
    fontSize: isWeb? taxiLinkFontSize(2.2) : taxiLinkFontSize(2),
    fontWeight: "500",
  },
  inputContainer: {
    width: isWeb ? taxiLinkFontSize(33) : "100%",
    height: isWeb ? taxiLinkFontSize(7.1) : taxiLinkFontSize(6.2),
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: taxiLinkFontSize(31),
    borderWidth: taxiLinkFontSize(0.05),
    borderColor: COLORS.gray85,
    marginTop: isWeb ? taxiLinkFontSize(1.6) : taxiLinkFontSize(1.2),
    backgroundColor: COLORS.blackBg,
  },
  icon: {
    width: taxiLinkFontSize(2.1),
    height: taxiLinkFontSize(2.1),
    marginLeft: taxiLinkFontSize(2),
    marginTop: taxiLinkFontSize(2),
    marginBottom: taxiLinkFontSize(2),
  },
  endIcon: {
    width: taxiLinkFontSize(2.9),
    height: taxiLinkFontSize(2.9),
    resizeMode: "contain",
    marginEnd: taxiLinkFontSize(2),
  },
  input: {
    color: COLORS.white,
    width: "87%",
    fontFamily: fontFamilies.InterRegular,
    fontSize: taxiLinkFontSize(1.8),
    fontWeight: "400",
    height: "80%",
    marginLeft: taxiLinkFontSize(1),
    outlineStyle: "none",
  },
});

export default InputField;
