
export const COLORS = {
    // base colors
    primary: "#FFCA29",
    secondary: "#FFFFFF", 


    // colors
    black: "#3E3636",
    white: "#FFFFFF",
    red:'#ED1C24',
    lightGray: "#EBF2F0",
    lightGray2: "#F6F6F7",
    lightGray3: "#EFEFF1",
    lightGray4: "#F8F8F9",
    transparent: "transparent",
    darkgray: '#898C95',
    blackBg: '#151515',
    kettleBlack: "#141113",
    pureBlack: '#000',
    grey: '#464546',
    gray85: '#D9D9D9',
    gray24: '#3D3D3D',
    lightBlack: '#060612',
    navyBlue:'#060612',
    offBlack: '#2D2D2D',
};
