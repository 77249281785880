import Inter from './Inter.ttf';
import Popins from './Popins.ttf';
import InterRegular from './InterRegular.ttf';
import Epilogue from './Epilogue.ttf';
import PoppinsBold from './PoppinsBold.ttf';
import PoppinsSemiBold from './PoppinsSemiBold.ttf';
import PoppinsRegular from './PoppinsRegular.ttf';
import PoppinsMedium from './PoppinsMedium.ttf';
import PoppinsExtraBold from './PoppinsExtraBold.ttf';
import PoppinsBlack from './PoppinsBlack.ttf';

const fontFamilies = {
    Inter,
     Popins,
    InterRegular,
    Epilogue,
    PoppinsBold,
    PoppinsSemiBold,
    PoppinsRegular,
    PoppinsMedium,
    PoppinsExtraBold,
    PoppinsBlack,
}

export {
    Inter,
    Popins,
    InterRegular,
    fontFamilies,
    Epilogue,
    PoppinsBold,
    PoppinsSemiBold,
    PoppinsRegular,
    PoppinsMedium,
    PoppinsExtraBold,
    PoppinsBlack,
};